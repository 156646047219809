export const protectedRoutes = [
  {
    routes: [
      '/my-account/sign-in-and-security',
      '/mi-cuenta/inicio-sesion-y-seguridad',
      '/mon-compte/connexion-et-securite',
      '/minha-conta/login-e-seguranca',
      '/my-account/sign-in-and-security/my-devices',
      '/mi-cuenta/inicio-sesion-y-seguridad/mis-dispositivos',
      '/mon-compte/connexion-et-securite/mes-appareils',
      '/minha-conta/login-e-seguranca/meus-dispositivos',
    ],
  },
]
