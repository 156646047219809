import { protectedRoutes } from '~/lib/models/security'

export default defineNuxtRouteMiddleware((to, _from) => {
  const appStore = useAppStore()

  for (const protectedRoute of protectedRoutes) {
    if (!appStore.canShowSignInAndSecurity && protectedRoute.routes.includes(to.path)) {
      return navigateTo('/')
    }
  }
})
